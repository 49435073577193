import React from "react";
import './logoSkills.css'

const LogoSkillsFront = () =>{

    return(
    <>
        <div className="ContainerSkills">
            <div className="logoContainer">
                <img style={{height: "44.45px"}} title="React" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K" alt="React JS." />
                <span aria-hidden className="react">React</span>
            </div>
            <div className="logoContainer">
                <img style={{width: "100px"}} title="Angular"
                    src="https://angular.io/assets/images/logos/angular/logo-nav@2x.png"
                    alt="Angular." />
            </div>
            <div className="logoContainer">
                <svg height="50" width="82" style={{
                    transform: "translateX(7%)",
                    shapeRendering: "auto",
                    width: "100px"
                }} version="1.1" viewBox="0 0 148 90" xlink="http://www.w3.org/1999/xlink" aria-label="Next JS.">
                    <title>Next JS</title>
                    <path d="M34.992 23.495h27.855v2.219H37.546v16.699h23.792v2.219H37.546v18.334h25.591v2.219H34.992v-41.69zm30.35 0h2.96l13.115 18.334 13.405-18.334L113.055.207 83.1 43.756l15.436 21.429H95.46L81.417 45.683 67.316 65.185h-3.018L79.85 43.756 65.343 23.495zm34.297 2.219v-2.219h31.742v2.219h-14.623v39.47h-2.554v-39.47H99.64zM.145 23.495h3.192l44.011 66.003L29.16 65.185 2.814 26.648l-.116 38.537H.145v-41.69zm130.98 38.801c-.523 0-.914-.405-.914-.928 0-.524.391-.929.913-.929.528 0 .913.405.913.929 0 .523-.385.928-.913.928zm2.508-2.443H135c.019.742.56 1.24 1.354 1.24.888 0 1.391-.535 1.391-1.539v-6.356h1.391v6.362c0 1.808-1.043 2.849-2.77 2.849-1.62 0-2.732-1.01-2.732-2.556zm7.322-.08h1.379c.118.853.95 1.395 2.149 1.395 1.117 0 1.937-.58 1.937-1.377 0-.685-.521-1.097-1.708-1.377l-1.155-.28c-1.62-.38-2.36-1.166-2.36-2.487 0-1.602 1.304-2.668 3.26-2.668 1.82 0 3.15 1.066 3.23 2.58h-1.354c-.13-.828-.85-1.346-1.894-1.346-1.1 0-1.832.53-1.832 1.34 0 .642.472 1.01 1.64 1.284l.987.243c1.838.43 2.596 1.178 2.596 2.53 0 1.72-1.33 2.799-3.453 2.799-1.987 0-3.323-1.029-3.422-2.637z" fill="white" fillRule="nonzero">
                    </path>
                </svg>
            </div>
            <div className="logoContainer">
                <svg style={{height: "50px"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 630 630" aria-label="JavaScript.">
                    <title>JavaScript</title>
                    <rect width="630" height="630" fill="#f7df1e"/>
                    <path d="m423.2 492.19c12.69 20.72 29.2 35.95 58.4 35.95 24.53 0 40.2-12.26 40.2-29.2 0-20.3-16.1-27.49-43.1-39.3l-14.8-6.35c-42.72-18.2-71.1-41-71.1-89.2 0-44.4 33.83-78.2 86.7-78.2 37.64 0 64.7 13.1 84.2 47.4l-46.1 29.6c-10.15-18.2-21.1-25.37-38.1-25.37-17.34 0-28.33 11-28.33 25.37 0 17.76 11 24.95 36.4 35.95l14.8 6.34c50.3 21.57 78.7 43.56 78.7 93 0 53.3-41.87 82.5-98.1 82.5-54.98 0-90.5-26.2-107.88-60.54zm-209.13 5.13c9.3 16.5 17.76 30.45 38.1 30.45 19.45 0 31.72-7.61 31.72-37.2v-201.3h59.2v202.1c0 61.3-35.94 89.2-88.4 89.2-47.4 0-74.85-24.53-88.81-54.075z"/>
                </svg>
                <span aria-hidden>JavaScript</span>
            </div>
            <div className="logoContainer">
                <svg aria-label="TypeScript." style={{backgroundColor: "white", borderRadius: "0.4rem"}} height="50" width="50" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                    <title>TypeScript</title>
                    <path clipRule="evenodd" d="m.98608 0h24.32332c.5446 0 .9861.436522.9861.975v24.05c0 .5385-.4415.975-.9861.975h-24.32332c-.544597 0-.98608-.4365-.98608-.975v-24.05c0-.538478.441483-.975.98608-.975zm13.63142 13.8324v-2.1324h-9.35841v2.1324h3.34111v9.4946h2.6598v-9.4946zm1.0604 9.2439c.4289.2162.9362.3784 1.5218.4865.5857.1081 1.2029.1622 1.8518.1622.6324 0 1.2331-.0595 1.8023-.1784.5691-.1189 1.0681-.3149 1.497-.5879s.7685-.6297 1.0187-1.0703.3753-.9852.3753-1.6339c0-.4703-.0715-.8824-.2145-1.2365-.1429-.3541-.3491-.669-.6186-.9447-.2694-.2757-.5925-.523-.9692-.7419s-.8014-.4257-1.2743-.6203c-.3465-.1406-.6572-.2771-.9321-.4095-.275-.1324-.5087-.2676-.7011-.4054-.1925-.1379-.3409-.2838-.4454-.4379-.1045-.154-.1567-.3284-.1567-.523 0-.1784.0467-.3392.1402-.4824.0935-.1433.2254-.2663.3959-.369s.3794-.1824.6269-.2392c.2474-.0567.5224-.0851.8248-.0851.22 0 .4523.0162.697.0486.2447.0325.4908.0825.7382.15.2475.0676.4881.1527.7218.2555.2337.1027.4495.2216.6475.3567v-2.4244c-.4015-.1514-.84-.2636-1.3157-.3365-.4756-.073-1.0214-.1095-1.6373-.1095-.6268 0-1.2207.0662-1.7816.1987-.5609.1324-1.0544.3392-1.4806.6203s-.763.6392-1.0104 1.0743c-.2475.4352-.3712.9555-.3712 1.5609 0 .7731.2268 1.4326.6805 1.9785.4537.546 1.1424 1.0082 2.0662 1.3866.363.146.7011.2892 1.0146.4298.3134.1405.5842.2865.8124.4378.2282.1514.4083.3162.5403.4946s.198.3811.198.6082c0 .1676-.0413.323-.1238.4662-.0825.1433-.2076.2676-.3753.373s-.3766.1879-.6268.2473c-.2502.0595-.5431.0892-.8785.0892-.5719 0-1.1383-.0986-1.6992-.2959-.5608-.1973-1.0805-.4933-1.5589-.8879z" fill="#3178c6" fillRule="evenodd">
                    </path>
                </svg>
                <span aria-hidden>TypeScript</span>
            </div>
            <div className="logoContainer">
                <svg aria-label="HTML5." style={{height: "70.56px"}} xmlns="http://www.w3.org/2000/svg"  viewBox="70 0 362.73401 511.99998" version="1.1">
                    <title>HTML5</title>
                    <path fill="#EEEEEE" d="M108.4 0h23v22.8h21.2V0h23v69h-23V46h-21v23h-23.2M206 23h-20.3V0h63.7v23H229v46h-23M259.5 0h24.1l14.8 24.3L313.2 0h24.1v69h-23V34.8l-16.1 24.8l-16.1-24.8v34.2h-22.6M348.7 0h23v46.2h32.6V69h-55.6"/>
                    <path fill="#e44d26" d="M107.6 471l-33-370.4h362.8l-33 370.2L255.7 512"/>
                    <path fill="#f16529" d="M256 480.5V131H404.3L376 447"/>
                    <path fill="#ebebeb" d="M142 176.3h114v45.4h-64.2l4.2 46.5h60v45.3H154.4M156.4 336.3H202l3.2 36.3 50.8 13.6v47.4l-93.2-26"/>
                    <path fill="#fff" d="M369.6 176.3H255.8v45.4h109.6M361.3 268.2H255.8v45.4h56l-5.3 59-50.7 13.6v47.2l93-25.8"/>
                </svg>
            </div>
            <div className="logoContainer">
                <svg aria-label="CSS3." style={{height: "70.56px"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 362.73401 511.99998" version="1.1">
                    <title>CSS3</title>
                    <g id="layer1" transform="translate(-193.633,-276.3622)">
                        <g id="g3013" transform="translate(119,276.3622)">
                        <polygon id="polygon2989" points="437.367,100.62 404.321,470.819 255.778,512 107.644,470.877 74.633,100.62 " fill="#264de4"/>
                        <polygon id="polygon2991" points="376.03,447.246 404.27,130.894 256,130.894 256,480.523 " fill="#2965f1"/>
                        <polygon id="polygon2993" points="150.31,268.217 154.38,313.627 256,313.627 256,268.217 " fill="#ebebeb"/>
                        <polygon id="polygon2995" points="256,176.305 255.843,176.305 142.132,176.305 146.26,221.716 256,221.716 " fill="#ebebeb"/>
                        <polygon id="polygon2997" points="256,433.399 256,386.153 255.801,386.206 205.227,372.55 201.994,336.333 177.419,336.333 156.409,336.333 162.771,407.634 255.791,433.457 " fill="#ebebeb"/>
                        <path fill="#EEEEEE" id="path2999" d="m 160,0 55,0 0,23 -32,0 0,23 32,0 0,23 -55,0 z" />
                        <path fill="#EEEEEE" id="path3001" d="m 226,0 55,0 0,20 -32,0 0,4 32,0 0,46 -55,0 0,-21 32,0 0,-4 -32,0 z" />
                        <path fill="#EEEEEE" id="path3003" d="m 292,0 55,0 0,20 -32,0 0,4 32,0 0,46 -55,0 0,-21 32,0 0,-4 -32,0 z" />
                        <polygon id="polygon3005" points="311.761,313.627 306.49,372.521 255.843,386.191 255.843,433.435 348.937,407.634 349.62,399.962 360.291,280.411 361.399,268.217 369.597,176.305 255.843,176.305 255.843,221.716 319.831,221.716 315.699,268.217 255.843,268.217 255.843,313.627 " fill="#ffffff"/>
                        </g>
                    </g>
                </svg>
            </div>
            <div className="logoContainer"  >
                <svg aria-label="Node JS." style={{width: '100px', height: "61.23px"}} xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 442.37 270.929">
                    <title>Node JS</title>
                    <defs>
                        <linearGradient id="b" x1="-.348" x2="1.251" gradientTransform="rotate(116.114 53.1 202.97) scale(86.48)" gradientUnits="userSpaceOnUse">
                            <stop offset=".3" stopColor="#3e863d"/>
                            <stop offset=".5" stopColor="#55934f"/>
                            <stop offset=".8" stopColor="#5aad45"/>
                        </linearGradient>
                        <linearGradient id="d" x1="-.456" x2=".582" gradientTransform="rotate(-36.46 550.846 -214.337) scale(132.798)" gradientUnits="userSpaceOnUse"><stop offset=".57" stopColor="#3e863d"/><stop offset=".72" stopColor="#619857"/>
                            <stop offset="1" stopColor="#76ac64"/>
                        </linearGradient>
                        <linearGradient id="f" x1=".043" x2=".984" gradientTransform="translate(192.862 279.652) scale(97.417)" gradientUnits="userSpaceOnUse">
                            <stop offset=".16" stopColor="#6bbf47"/>
                            <stop offset=".38" stopColor="#79b461"/>
                            <stop offset=".47" stopColor="#75ac64"/>
                            <stop offset=".7" stopColor="#659e5a"/>
                            <stop offset=".9" stopColor="#3e863d"/>
                        </linearGradient>
                        <clipPath id="a">
                            <path d="M239.03 226.605l-42.13 24.317a5.085 5.085 0 00-2.546 4.406v48.668c0 1.817.968 3.496 2.546 4.406l42.133 24.336a5.1 5.1 0 005.09 0l42.126-24.336a5.096 5.096 0 002.54-4.406v-48.668c0-1.816-.97-3.496-2.55-4.406l-42.12-24.317a5.123 5.123 0 00-5.1 0"/>
                        </clipPath>
                        <clipPath id="c">
                            <path d="M195.398 307.086c.403.523.907.976 1.5 1.316l36.14 20.875 6.02 3.46c.9.52 1.926.74 2.934.665.336-.027.672-.09 1-.183l44.434-81.36c-.34-.37-.738-.68-1.184-.94l-27.586-15.93-14.582-8.39a5.318 5.318 0 00-1.32-.53zm0 0"/>
                        </clipPath>
                        <clipPath id="e">
                            <path d="M241.066 225.953a5.14 5.14 0 00-2.035.652l-42.01 24.247 45.3 82.51c.63-.09 1.25-.3 1.81-.624l42.13-24.336a5.105 5.105 0 002.46-3.476l-46.18-78.89a5.29 5.29 0 00-1.03-.102c-.14 0-.28.007-.42.02"/>
                        </clipPath>
                    </defs>
                    <path fill="#689f63" d="M218.647 270.93c-1.46 0-2.91-.383-4.19-1.12l-13.337-7.896c-1.992-1.114-1.02-1.508-.363-1.735 2.656-.93 3.195-1.14 6.03-2.75.298-.17.688-.11.993.07l10.246 6.08c.37.2.895.2 1.238 0l39.95-23.06c.37-.21.61-.64.61-1.08v-46.1c0-.46-.24-.87-.618-1.1l-39.934-23.04c-.37-.22-.86-.22-1.23 0l-39.926 23.04c-.387.22-.633.65-.633 1.09v46.1c0 .44.24.86.62 1.07l10.94 6.32c5.94 2.97 9.57-.53 9.57-4.05v-45.5c0-.65.51-1.15 1.16-1.15h5.06c.63 0 1.15.5 1.15 1.15v45.52c0 7.92-4.32 12.47-11.83 12.47-2.31 0-4.13 0-9.21-2.5l-10.48-6.04a8.447 8.447 0 01-4.19-7.29v-46.1c0-3 1.6-5.8 4.19-7.28l39.99-23.07c2.53-1.43 5.89-1.43 8.4 0l39.94 23.08a8.428 8.428 0 014.19 7.28v46.1c0 2.99-1.61 5.78-4.19 7.28l-39.94 23.07a8.397 8.397 0 01-4.21 1.12"/>
                    <path fill="#689f63" d="M230.987 239.164c-17.48 0-21.145-8.024-21.145-14.754 0-.64.516-1.15 1.157-1.15h5.16c.57 0 1.05.415 1.14.978.78 5.258 3.1 7.91 13.67 7.91 8.42 0 12-1.902 12-6.367 0-2.57-1.02-4.48-14.1-5.76-10.94-1.08-17.7-3.49-17.7-12.24 0-8.06 6.8-12.86 18.19-12.86 12.79 0 19.13 4.44 19.93 13.98a1.164 1.164 0 01-1.16 1.26h-5.19c-.54 0-1.01-.38-1.12-.9-1.25-5.53-4.27-7.3-12.48-7.3-9.19 0-10.26 3.2-10.26 5.6 0 2.91 1.26 3.76 13.66 5.4 12.28 1.63 18.11 3.93 18.11 12.56 0 8.7-7.26 13.69-19.92 13.69m48.66-48.89h1.34c1.1 0 1.31-.77 1.31-1.22 0-1.18-.81-1.18-1.26-1.18h-1.38zm-1.63-3.78h2.97c1.02 0 3.02 0 3.02 2.28 0 1.59-1.02 1.92-1.63 2.12 1.19.08 1.27.86 1.43 1.96.08.69.21 1.88.45 2.28h-1.83c-.05-.4-.33-2.6-.33-2.72-.12-.49-.29-.73-.9-.73h-1.51v3.46h-1.67zm-3.57 4.3c0 3.58 2.89 6.48 6.44 6.48 3.58 0 6.47-2.96 6.47-6.48 0-3.59-2.93-6.44-6.48-6.44-3.5 0-6.44 2.81-6.44 6.43m14.16.03c0 4.24-3.47 7.7-7.7 7.7-4.2 0-7.7-3.42-7.7-7.7 0-4.36 3.58-7.7 7.7-7.7 4.15 0 7.69 3.35 7.69 7.7"/>
                    <path fill="#fff" fillRule="evenodd" d="M94.936 90.55c0-1.84-.97-3.53-2.558-4.445l-42.356-24.37a4.946 4.946 0 00-2.328-.67h-.438c-.812.03-1.613.25-2.34.67L2.562 86.105A5.154 5.154 0 000 90.555l.093 65.64c0 .91.47 1.76 1.27 2.21.78.48 1.76.48 2.54 0l25.18-14.42c1.59-.946 2.56-2.618 2.56-4.44V108.88a5.1 5.1 0 012.555-4.43l10.72-6.174a5.086 5.086 0 012.56-.688c.876 0 1.77.226 2.544.687l10.715 6.172c1.586.91 2.56 2.6 2.56 4.43v30.663c0 1.82.983 3.5 2.565 4.44l25.164 14.41a2.5 2.5 0 002.56 0 2.568 2.568 0 001.268-2.21zm199.868 34.176c0 .457-.243.88-.64 1.106l-14.548 8.386a1.282 1.282 0 01-1.277 0l-14.55-8.386c-.4-.227-.64-.65-.64-1.106V107.93c0-.458.24-.88.63-1.11l14.54-8.4c.4-.23.89-.23 1.29 0l14.55 8.4c.4.23.64.652.64 1.11zM298.734.324a2.568 2.568 0 00-2.544.027c-.78.46-1.262 1.3-1.262 2.21v65a1.788 1.788 0 01-2.684 1.55L281.634 63a5.108 5.108 0 00-5.112 0l-42.37 24.453a5.105 5.105 0 00-2.56 4.42v48.92c0 1.83.977 3.51 2.56 4.43l42.37 24.47c1.582.91 3.53.91 5.117 0l42.37-24.48c1.58-.92 2.56-2.6 2.56-4.43V18.863a5.128 5.128 0 00-2.63-4.47zm141.093 107.164a5.116 5.116 0 002.543-4.422V91.21c0-1.824-.97-3.507-2.547-4.425l-42.1-24.44a5.113 5.113 0 00-5.13 0l-42.36 24.45c-1.59.92-2.56 2.6-2.56 4.43v48.9c0 1.84.99 3.54 2.58 4.45l42.09 23.99c1.55.89 3.45.9 5.02.03l25.46-14.15c.8-.45 1.31-1.3 1.31-2.22 0-.92-.49-1.78-1.29-2.23l-42.62-24.46c-.8-.45-1.29-1.3-1.29-2.21v-15.34c0-.916.48-1.76 1.28-2.216l13.26-7.65c.79-.46 1.76-.46 2.55 0l13.27 7.65c.79.45 1.28 1.3 1.28 2.21v12.06c0 .91.49 1.76 1.28 2.22.79.45 1.77.45 2.56-.01zm0 0"/>
                    <path fill="#689f63" fillRule="evenodd" d="M394.538 105.2a.97.97 0 01.98 0l8.13 4.69c.304.176.49.5.49.85v9.39c0 .35-.186.674-.49.85l-8.13 4.69a.97.97 0 01-.98 0l-8.125-4.69a.979.979 0 01-.5-.85v-9.39c0-.35.18-.674.49-.85zm0 0"/>
                    <g clipPath="url(#a)" transform="translate(-78.306 -164.016)">
                        <path fill="url(#b)" d="M331.363 246.793l-118.715-58.19-60.87 124.174L270.49 370.97zm0 0"/>
                    </g>
                    <g clipPath="url(#c)" transform="translate(-78.306 -164.016)">
                        <path fill="url(#d)" d="M144.07 264.004l83.825 113.453 110.86-81.906-83.83-113.45zm0 0"/>
                    </g>
                    <g clipPath="url(#e)" transform="translate(-78.306 -164.016)">
                        <path fill="url(#f)" d="M197.02 225.934v107.43h91.683v-107.43zm0 0"/>
                    </g>
                </svg>
            </div>
            {/* <div className="logoContainer" style={{width: "110px"}}>
                <img style={{width: "110px"}} title="Python" src="https://www.python.org/static/img/python-logo.png" alt="Python." />
            </div> */}
            <div className="logoContainer">
                <img style={{height: "81.44px"}} title="Firebase" src="https://firebase.google.com/downloads/brand-guidelines/SVG/logo-vertical.svg?hl=es" alt="Firebase." />
            </div>
            {/* <div className="logoContainer" >
                <img style={{width: "100px", height: "69.09px"}} title="MySQL" src="https://labs.mysql.com/common/logos/mysql-logo.svg?v2" alt="MySQL." />
            </div> */}
            <div className="logoContainer">
                <img style={{height: "50px"}} title="MongoDB" src="https://www.mongodb.com/assets/images/global/favicon.ico" fill="#EEEEEE" alt="Mongo DB."/>
                <span aria-hidden>MongoDB</span>
            </div>
            <div className="logoContainer">
                <img style={{height: "50px"}} title="MongoDB" src="https://upload.wikimedia.org/wikipedia/de/thumb/8/8c/Microsoft_SQL_Server_Logo.svg/1200px-Microsoft_SQL_Server_Logo.svg.png" fill="#EEEEEE" alt="Mongo DB."/>
                <span aria-hidden>SQL Server</span>
            </div>
        </div>
    </>
    )
}

export default LogoSkillsFront;